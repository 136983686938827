import { render, staticRenderFns } from "./CarouselResponsiveBreakpoints.vue?vue&type=template&id=7819ad08&"
import script from "./CarouselResponsiveBreakpoints.vue?vue&type=script&lang=js&"
export * from "./CarouselResponsiveBreakpoints.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7819ad08')) {
      api.createRecord('7819ad08', component.options)
    } else {
      api.reload('7819ad08', component.options)
    }
    module.hot.accept("./CarouselResponsiveBreakpoints.vue?vue&type=template&id=7819ad08&", function () {
      api.rerender('7819ad08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/extra-components/carousel/CarouselResponsiveBreakpoints.vue"
export default component.exports