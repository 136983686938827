var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticClass: "carousel-example",
      attrs: { title: "Pagination", "code-toggler": "" },
    },
    [
      _c(
        "swiper",
        {
          key: _vm.$vs.rtl,
          attrs: {
            options: _vm.swiperOption,
            dir: _vm.$vs.rtl ? "rtl" : "ltr",
          },
        },
        [
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-16.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-19.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-5.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-9.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("div", {
            staticClass: "swiper-pagination",
            attrs: { slot: "pagination" },
            slot: "pagination",
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <div class="carousel-example" :dir="$vs.rtl ? \'rtl\' : \'ltr\'" :key="$vs.rtl">\n        <swiper :options="swiperOption">\n            <swiper-slide>\n              <img class="responsive" src="@/assets/images/pages/carousel/banner-16.jpg" alt="banner">\n            </swiper-slide>\n            <swiper-slide>\n              <img class="responsive" src="@/assets/images/pages/carousel/banner-19.jpg" alt="banner">\n            </swiper-slide>\n            <swiper-slide>\n              <img class="responsive" src="@/assets/images/pages/carousel/banner-5.jpg" alt="banner">\n            </swiper-slide>\n            <swiper-slide>\n              <img class="responsive" src="@/assets/images/pages/carousel/banner-9.jpg" alt="banner">\n            </swiper-slide>\n            <div class="swiper-pagination" slot="pagination"></div>\n        </swiper>\n    </div>\n</template>\n\n<script>\nimport \'swiper/dist/css/swiper.min.css\'\nimport { swiper, swiperSlide } from \'vue-awesome-swiper\'\n\nexport default {\n    data() {\n        return {\n            swiperOption: {\n                pagination: {\n                    el: \'.swiper-pagination\'\n                }\n            }\n        }\n    },\n    components: {\n        swiper,\n        swiperSlide\n    }\n}\n</script>\n          '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }