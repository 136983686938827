var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "extra-component-vue-awesome-swiper-demo" } },
    [
      _c("carousel-default"),
      _c("carousel-navigation"),
      _c("carousel-pagination"),
      _c("carousel-progress"),
      _c("carousel-multiple-slides-per-view"),
      _c("carousel-multi-row-slides-layout"),
      _c("carousel-effect-fade"),
      _c("carousel-3d-effect"),
      _c("carousel-3d-coverflow-effect"),
      _c("carousel-autoplay"),
      _c("carousel-gallery"),
      _c("carousel-parallax"),
      _c("carousel-lazy-loading"),
      _c("carousel-responsive-breakpoints"),
      _c("carousel-virtual-slides"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }