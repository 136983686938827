var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticClass: "carousel-example",
      attrs: { title: "Fade Effect", "code-toggler": "" },
    },
    [
      _c(
        "swiper",
        {
          key: _vm.$vs.rtl,
          attrs: {
            options: _vm.swiperOption,
            dir: _vm.$vs.rtl ? "rtl" : "ltr",
          },
        },
        [
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-16.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-10.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-15.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-18.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("div", {
            staticClass: "swiper-pagination swiper-pagination-white",
            attrs: { slot: "pagination" },
            slot: "pagination",
          }),
          _c("div", {
            staticClass: "swiper-button-prev swiper-button-white",
            attrs: { slot: "button-prev" },
            slot: "button-prev",
          }),
          _c("div", {
            staticClass: "swiper-button-next swiper-button-white",
            attrs: { slot: "button-next" },
            slot: "button-next",
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <swiper :options="swiperOption" :dir="$vs.rtl ? \'rtl\' : \'ltr\'" :key="$vs.rtl">\n    <swiper-slide>\n      <img class="responsive" src="@/assets/images/pages/carousel/banner-18.jpg" alt="banner">\n    </swiper-slide>\n    <swiper-slide>\n      <img class="responsive" src="@/assets/images/pages/carousel/banner-15.jpg" alt="banner">\n    </swiper-slide>\n    <swiper-slide>\n      <img class="responsive" src="@/assets/images/pages/carousel/banner-10.jpg" alt="banner">\n    </swiper-slide>\n    <swiper-slide>\n      <img class="responsive" src="@/assets/images/pages/carousel/banner-16.jpg" alt="banner">\n    </swiper-slide>\n    <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>\n    <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>\n    <div class="swiper-button-next swiper-button-white" slot="button-next"></div>\n  </swiper>\n</template>\n\n<script>\nimport \'swiper/dist/css/swiper.min.css\'\nimport { swiper, swiperSlide } from \'vue-awesome-swiper\'\n\nexport default {\n  data() {\n    return {\n      swiperOption: {\n        spaceBetween: 30,\n        effect: \'fade\',\n        pagination: {\n            el: \'.swiper-pagination\',\n            clickable: true\n        },\n        navigation: {\n            nextEl: \'.swiper-button-next\',\n            prevEl: \'.swiper-button-prev\'\n        }\n      }\n    }\n  },\n  components: {\n    swiper,\n    swiperSlide\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }