var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticClass: "carousel-example",
      attrs: { title: "Default", "code-toggler": "" },
    },
    [
      _c(
        "swiper",
        { key: _vm.$vs.rtl, attrs: { dir: _vm.$vs.rtl ? "rtl" : "ltr" } },
        [
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-18.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-15.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-10.jpg"),
                alt: "banner",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              staticClass: "responsive",
              attrs: {
                src: require("@/assets/images/pages/carousel/banner-16.jpg"),
                alt: "banner",
              },
            }),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <div class="carousel-example">\n          <swiper :dir="$vs.rtl ? \'rtl\' : \'ltr\'" :key="$vs.rtl">\n            <swiper-slide>\n              <img class="responsive" src="@/assets/images/pages/carousel/banner-18.jpg" alt="banner">\n            </swiper-slide>\n            <swiper-slide>\n              <img class="responsive" src="@/assets/images/pages/carousel/banner-15.jpg" alt="banner">\n            </swiper-slide>\n            <swiper-slide>\n              <img class="responsive" src="@/assets/images/pages/carousel/banner-10.jpg" alt="banner">\n            </swiper-slide>\n            <swiper-slide>\n              <img class="responsive" src="@/assets/images/pages/carousel/banner-16.jpg" alt="banner">\n            </swiper-slide>\n          </swiper>\n    </div>\n</template>\n\n<script>\nimport \'swiper/dist/css/swiper.min.css\'\nimport { swiper, swiperSlide } from \'vue-awesome-swiper\'\n\nexport default {\n    components: {\n        swiper,\n        swiperSlide\n    }\n}\n</script>\n          '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }